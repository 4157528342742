import ReactDOM from 'react-dom/client';
import BTCheckout, { BTCheckoutOverlay } from '../components/BTCheckout/BTCheckout';
import { CheckoutState } from '../components/Checkout/enum/CheckoutState'

class CheckoutModal {

	constructor() {
		this.bodyElem = null
	}

	mount(props) {
		if(this.bodyElem != null) { return }
		this.bodyElem = document.createElement("div")
		this.bodyElem.style.position = "fixed"
		this.bodyElem.style.top = "0px"
		this.bodyElem.style.bottom = "0px"
		this.bodyElem.style.left = "0px"
		this.bodyElem.style.right = "0px"
		this.bodyElem.style.zIndex = "9999999"
		this.bodyElem.style.willChange = "background"

		//document.body.appendChild(this.bodyElem);
		document.body.insertBefore(this.bodyElem, document.body.firstChild);
		const root = ReactDOM.createRoot(this.bodyElem);
		root.render(
			<BTCheckoutOverlay
				{...props}
			/>
		);
	}

	unmount = () => {
		if(this.bodyElem == null) { return }
		this.bodyElem.remove()
		this.bodyElem = null
	}
}

window.BTCheckout = {
	createEmbedded: ({
		elementId,
		eventId,
		width,
		height
	}) => {
		const elem = document.getElementById(elementId)

		const checkoutWidth = width ?? "100%"
		const checkoutHeight = height ?? "450px"
		const modal = new CheckoutModal()

		const onOpenModal = () => {
			modal.mount({
				elementId: elementId,
				eventId: eventId
			})
		}

		const onCloseModal = () => {
			modal.unmount()
		}

		const root = ReactDOM.createRoot(elem);
		root.render(
			<BTCheckout
				elementId={elementId}
				eventId={eventId}
				width={checkoutWidth}
				height={checkoutHeight}
				onOpenModal={onOpenModal}
				onCloseModal={onCloseModal}
			/>
		);
	},

	createCompact: ({
		elementId,
		eventId,
		width,
		height,
		darkMode
	}) => {
		let cartId = ""
		const elem = document.getElementById(elementId)
		const modal = new CheckoutModal()

		const checkoutWidth = width ?? null
		const checkoutHeight = height ?? null

		const onStateChange = (data) => {
			cartId = data.cartId
		}

		const onStateChangeModal = (data) => {
			if(data.state == CheckoutState.COLLECTING) {
				modal.unmount()
			}
		}

		const onOpenModal = (data) => {
			modal.mount({
				elementId: elementId,
				eventId: eventId,
				cartId: cartId,
				onStateChange: (data.listenState)
					? onStateChangeModal
					: () => {}
			})
		}

		const onCloseModal = () => {
			modal.unmount()
		}

		const mountBase = () => {
			const root = ReactDOM.createRoot(elem);
			root.render(
				<BTCheckout
					elementId={elementId}
					eventId={eventId}
					width={checkoutWidth}
					height={checkoutHeight}
					minWidth="300px"
					minHeight={null}
					onOpenModal={onOpenModal}
					onCloseModal={onCloseModal}
					onStateChange={onStateChange}
					compact={true}
					autoSizing={true}
					darkMode={(darkMode === true)}
				/>
			);
		}

		mountBase()
	}
}
